<template>
  <div>
    <div
      v-if="isFileUploaded && displayOptions"
      :class="['file-uploaded', `file-uploaded-${theme}`]"
    >
      <div :class="['file-uploaded-content', `file-uploaded-content-${theme}`]">
        <div class="file-uploaded-icon-container">
          <img class="file-uploaded-image" :src="getImgUrl" alt="Page facing up" />
        </div>

        <div class="file-uploaded-text">
          <span class="file-uploaded-text-name">{{ file.name }}</span>
          <span class="file-uploaded-text-size">{{ fileFormatBytes(file.size) }}</span>
        </div>
      </div>
      <template>
        <Transition :key="loading" name="icon-expand">
          <div v-if="loading" class="flex justify-center">
            <AppIcon class="animate-spin" size="32">
              <IconSpinner class="text-orange" />
            </AppIcon>
          </div>
          <div v-else class="file-uploaded-button">
            <AppMenu stay-open-after-menu-item-click>
              <template #menuItems>
                <ul class="flex flex-col type-caption-bold text-main-black">
                  <li
                    v-if="isDownloadEnabled"
                    class="flex items-center py-2 px-4 cursor-pointer hover:bg-gray-lighter"
                    @click="downloadFile(file)"
                    @keypress="downloadFile(file)"
                  >
                    <span class="pr-2">Download & View</span>
                    <AppIcon width="20px">
                      <IconPackage />
                    </AppIcon>
                  </li>
                  <li class="border border-tertiary-gray-darker"></li>
                  <li class="py-2 px-4 cursor-pointer hover:bg-gray-lighter" @click="clearInput">
                    Delete
                  </li>
                </ul>
              </template>
            </AppMenu>
          </div>
        </Transition>
      </template>
    </div>

    <div
      v-else-if="!isFileUploaded && !isMobileView"
      class="xe-upload-container"
      :class="{ 'xe-upload-container-error': fileSizeExceeded || fileTypeNotAllowed }"
      @drop.prevent="dropFiles"
      @dragover.prevent
    >
      <AppRipple>
        <label for="fileUploadInput" class="xe-upload-label">
          <input
            v-show="false"
            id="fileUploadInput"
            type="file"
            class="xe-upload-input"
            :multiple="multiple"
            :accept="accept"
            @change="uploadFile"
          />
          <div class="xe-upload-description">
            <AppIcon class="xe-upload-description-icon" name="Add File">
              <IconDocumentAdd />
            </AppIcon>
            <p class="xe-upload-description-content">
              Drag and drop here or <br />
              <span class="text-blue">click to upload</span> (PDF/JPG/PNG)
            </p>
            <p class="xe-upload-description-subcontent">File cannot exceed {{ maxSize }} MB</p>
          </div>
        </label>
      </AppRipple>
    </div>

    <label
      v-else-if="!isFileUploaded && isMobileView"
      for="fileMobileUploadInput"
      class="
        xe-upload-mobile-container-border
        flex flex-row
        justify-between
        rounded-xl
        shadow-ria-1
        w-full
        p-6
        cursor-pointer
      "
    >
      <input
        v-show="false"
        id="fileMobileUploadInput"
        type="file"
        class="xe-upload-input"
        :multiple="multiple"
        :accept="accept"
        @change="uploadFile"
      />
      <div class="xe-upload-mobile-container-text">Upload photo</div>
      <AppIcon class="xe-upload-mobile-description-icon" name="Image" color="#5C667B">
        <IconImage />
      </AppIcon>
    </label>

    <div v-else class="flex flex-row justify-between rounded-xl shadow-ria-1 w-full sm:w-96 p-4">
      <div class="basis-4/5 flex flex-row">
        <img class="flex-none w-6 h-6 mr-2 mt-1" src="./page-facing-up.svg" alt="Page facing up" />
        <div class="flex flex-col w-64 h-12">
          <span class="truncate w-5/6">{{ file.name }}</span>
          <span class="font-light">{{ fileFormatBytes(file.size) }}</span>
        </div>
      </div>
      <div class="basis-1/5 pt-2">
        <button @click="clearInput">
          <AppIcon class="xe-uploaded-description-icon" width="20px" name="Clear">
            <IconTrashCan />
          </AppIcon>
        </button>
      </div>
    </div>

    <p v-if="fileSizeExceeded && !isFileUploaded" class="pt-2 type-helper text-red">
      The file size exceeds {{ maxSize }} MB
    </p>
    <p v-if="fileTypeNotAllowed" class="pt-2 type-helper text-red">Invalid Format</p>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch, computed } from '@vue/composition-api'
import AppMenu from '@/components/AppMenu/AppMenu.vue'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import {
  IconDocumentAdd,
  IconTrashCan,
  IconPackage,
  IconImage,
  IconSpinner,
} from '@moneytransfer.ui/euronet-icons'
const JSZip = require('jszip')

export default {
  name: 'XeFileUpload',
  components: {
    AppIcon,
    AppMenu,
    IconDocumentAdd,
    IconTrashCan,
    IconPackage,
    IconImage,
    IconSpinner,
  },
  props: {
    data: {
      type: [Array, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: null,
    },
    accept: {
      type: Array,
      default: null,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    displayOptions: {
      type: Boolean,
      default: false,
    },
    isMobileView: {
      type: Boolean,
      default: false,
    },
    isDownloadEnabled: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { clear: iscleared } = toRefs(props)
    const isFileUploaded = ref(false)
    const fileSizeExceeded = ref(false)
    const fileTypeNotAllowed = ref(false)
    const filesUploaded = ref([])
    const file = reactive({
      name: null,
      size: null,
      type: null,
      data: null,
    })

    // Utils
    const fileFormatBytes = (bytes, decimals = 2) => {
      // Format bytes depending on the size
      if (bytes === 0) {
        return '0 Bytes'
      }
      const kb = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(kb))
      return parseFloat((bytes / Math.pow(kb, i)).toFixed(dm)) + sizes[i]
    }

    const validateMaxSize = (file) => {
      //validate max size, comparing the size in KBs
      if (props.maxSize !== null && props.maxSize * 1024 < file.size / 1024) {
        fileSizeExceeded.value = true
        throw 'File is too large. Max size' + ' ' + props.maxSize + 'mb'
      }
      fileSizeExceeded.value = false
    }

    const validateFileType = (file) => {
      //validate the file types
      if (props.accept !== null && props.accept.length > 0) {
        let validType = props.accept.includes(file.type)
        if (!validType) {
          //show file extensions instead of "tecnical" files type.
          let acceptedExtensions = props.accept.map((type) => {
            let fileExtension = type.split('/')[1]
            return `.${fileExtension}`
          })
          fileTypeNotAllowed.value = true
          throw 'File not supported. Upload' + ' ' + acceptedExtensions.join(' ')
        }
        fileTypeNotAllowed.value = false
      }
    }

    async function blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }

    const setFiles = (files) => {
      // eslint-disable-next-line prettier/prettier
      ([...files]).forEach((file, i) => {
        blobToBase64(files[i])
          .then(() => {
            // Check the max size
            validateMaxSize(file)
            // Validate the file type
            validateFileType(file)
            // Set Data
            filesUploaded.value.push({
              name: file.name,
              type: file.type,
              size: file.size,
              data: file,
            })
          })
          .catch((error) => {
            emit('validationError', error)
          })
      })
      isFileUploaded.value = true
      emit('uploaded', file)
    }

    const setFile = (fileData) => {
      // Set file data
      blobToBase64(fileData)
        .then(() => {
          // Check the max size
          validateMaxSize(fileData)
          // Validate the file type
          validateFileType(fileData)
          // Set Data
          file.name = fileData.name
          file.type = fileData.type
          file.size = fileData.size
          file.data = fileData
          isFileUploaded.value = true
          emit('uploaded', file)
        })
        .catch((error) => {
          emit('validationError', error)
        })
    }

    const dropFiles = (event) => {
      let droppedFiles
      if (!props.multiple) {
        // Single file upload
        uploadFile(event)
      } else if (props.multiple && event.target.files) {
        // Multiple files (file input) upload
        droppedFiles = event.target.files
      } else {
        // Multiple files (drag & drop) upload
        droppedFiles = event.dataTransfer.files
      }

      // Set files
      if (!droppedFiles) {
        return
      }

      // Set file data
      setFiles(droppedFiles)
    }

    const uploadFile = (event) => {
      let uploadedFile

      if (props.multiple) {
        // Multiple file upload
        dropFiles(event)
      } else if (event.dataTransfer) {
        // Single file (drag & drop) upload
        uploadedFile = event.dataTransfer.files[0]
      } else {
        // Single file (file input) upload
        uploadedFile = event.target.files[0]
      }

      // To avoid empty file issues
      if (!uploadedFile) {
        return
      }
      // Set file data
      setFile(uploadedFile)
    }

    const clearInput = (selectedFile) => {
      // Remove files (drag & drop)
      if (filesUploaded.value.length > 0 && selectedFile) {
        filesUploaded.value = filesUploaded.value.filter((file) => file !== selectedFile)
      } else {
        // Remove file (file input)
        file.name = null
        file.size = null
        file.type = null
        file.data = null
      }
      //  Go to upload view
      if (!selectedFile || filesUploaded.value.length === 0) {
        isFileUploaded.value = false
        fileSizeExceeded.value = false
        emit('cleared')
        emit('uploaded', null)
      }
    }

    const downloadBase = (blob, name) => {
      // Convert your base64 file into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob)

      // Create a link element
      const link = document.createElement('a')

      // Set link's href to point to the Blob URL
      link.href = blobUrl
      link.download = name

      // Append link to the body
      document.body.appendChild(link)

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      )

      // Remove link from body
      document.body.removeChild(link)
    }

    // Download File
    const downloadFile = (file) => {
      JSZip.loadAsync(file.data).then((zip) => {
        zip.files[file.name].async('blob').then((fileData) => {
          downloadBase(fileData, file.name)
        })
      })
    }

    // Listening for clear changes
    watch(iscleared, (isClear) => (isClear ? clearInput() : null))

    // get preserved data
    if (props.data?.name) {
      const { name, size, type, data } = props.data
      // Remove file (file input)
      file.name = name
      file.size = size
      file.type = type
      file.data = data
      isFileUploaded.value = true
    }

    const getImgUrl = computed(() => {
      const themeMap = { default: 'page-facing-up', modern: 'custom-icon-icon' }
      // './page-facing-up.svg' : 'custom-icon-icon'
      return require('./' + themeMap[props.theme] + '.svg')
    })

    return {
      isFileUploaded,
      file,
      fileSizeExceeded,
      filesUploaded,
      fileTypeNotAllowed,
      downloadFile,
      clearInput,
      fileFormatBytes,
      dropFiles,
      uploadFile,
      getImgUrl,
    }
  },
}
</script>

<style lang="postcss" scoped>
.xe {
  &-upload-mobile-container-border {
    border: 0.5px solid #dddddd;
  }

  &-upload-mobile-container-text {
    @apply font-bold text-base;
    color: #001133cc;
  }
  /* Upload file */
  &-upload-container {
    @apply m-auto bg-gray-lightest;
    @apply border-2 border-dashed border-gray-light rounded-lg;
  }

  &-upload-container-error {
    @apply border border-red;
  }

  &-upload-label {
    @apply flex justify-center px-3 py-6;
    @apply cursor-pointer;
  }

  &-upload-description {
    @apply flex flex-col justify-center;

    &-icon {
      @apply self-center;
    }

    &-content {
      @apply mt-4;
      @apply text-center type-caption text-gray;
    }

    &-subcontent {
      @apply mt-4;
      @apply text-center type-caption-xs text-text-gray-secondary;
    }
  }

  /* Uploaded file(s)  */
  &-uploaded-card[data-v-0662a41a] {
    @apply rounded-xl;
    @apply shadow-ria-1;

    .card-content[data-v-0662a41a] {
      @apply p-0 py-6;

      .card-content-block[data-v-0662a41a] {
        @apply mb-0;
      }
    }
  }

  &-uploaded-description {
    @apply flex justify-center py-4;
    @apply border-b border-solid border-gray-lighter;

    &:first-of-type {
      @apply pt-0 pb-3;
    }

    &:last-of-type {
      @apply pb-0 border-none;
    }

    &:last-of-type {
      @apply border-none;
    }

    &-left {
      @apply self-start pr-2;
    }

    &-center {
      @apply self-start;
    }

    &-right {
      @apply self-center pl-5;

      &:focus {
        @apply outline-none;
      }
    }

    &-name {
      @apply block;
      @apply type-caption;

      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-size {
      @apply type-caption text-gray-light;
    }

    &-icon {
      @apply text-blue !important;

      &:hover {
        @apply text-blue-button-hover !important;
      }
    }
  }
}

.file-uploaded {
  @apply flex flex-row justify-between rounded-xl w-full p-4;

  &-text {
    @apply flex flex-col w-60 h-12;

    &-name {
      @apply truncate w-5/6;
    }

    &-size {
      @apply font-light;
    }
  }

  &-default {
    @apply shadow-ria-1;
    .file-uploaded-image {
      @apply flex-none w-6 h-6 mr-2 mt-1;
    }
  }

  &-modern {
    @apply px-0;

    .file-uploaded-content-modern {
      @apply w-10/12;

      @screen sm {
        @apply w-full;
      }
    }
    .file-uploaded-icon-container {
      @apply flex justify-center items-center;
      @apply bg-tertiary-gray-lighter border border-gray-darker rounded-xl;
      min-width: 3rem;
      @apply h-12;

      @screen md {
        min-width: 3.5rem;
        @apply h-14;
      }
    }

    .file-uploaded-image {
      @apply w-4;

      @screen sm {
        @apply w-5;
      }
    }

    .file-uploaded-text {
      @media (max-width: 360px) {
        @apply w-8/12;
      }

      @apply flex flex-col justify-center w-full h-full pl-4;
    }

    .file-uploaded-text-name {
      @apply type-body-bold;

      @media (max-width: 320px) {
        width: 60%;
      }

      @screen md {
        @apply w-full;
      }
    }

    .file-uploaded-text-size {
      color: rgba(179, 184, 194, 1);
      @apply type-caption;
    }

    .file-uploaded-button {
      @apply flex items-center pt-0;
    }
  }

  &-content {
    @apply flex flex-row;
  }

  &-button {
    @apply pt-2;
  }
}
</style>
