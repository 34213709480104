<template>
  <div>
    <slot>
      <AppButton theme="icon" @click="toggle">
        <AppIcon>
          <IconOverflowMenuVertical />
        </AppIcon>
      </AppButton>
    </slot>
    <Transition name="p-connected-overlay" @enter="onEnter" @leave="onLeave">
      <div v-if="menuVisible" ref="container" class="menu">
        <ul class="menu-list" role="menu">
          <!-- @slot menuItems: Slot for menu items -->
          <slot name="menuItems"></slot>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import DomHandler from './DomHandler'
import AppButton from '@/components/AppButton/AppButton'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconOverflowMenuVertical } from '@moneytransfer.ui/euronet-icons'

export default {
  components: {
    AppButton,
    AppIcon,
    IconOverflowMenuVertical,
  },
  props: {
    appendTo: {
      type: String,
      default: null,
    },
    autoZIndex: {
      type: Boolean,
      default: true,
    },
    baseZIndex: {
      type: Number,
      default: 0,
    },
    stayOpenAfterMenuItemClick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuVisible: false,
    }
  },
  target: null,
  outsideClickListener: null,
  resizeListener: null,
  relativeAlign: false,
  beforeDestroy() {
    this.restoreAppend()
    this.unbindResizeListener()
    this.unbindOutsideClickListener()
    this.target = null
  },
  methods: {
    toggle(event) {
      event.stopPropagation()
      if (event.cancelable) {
        event.preventDefault()
      }

      if (this.menuVisible) {
        this.hide()
      } else {
        this.show(event)
      }
    },
    show(event) {
      this.menuVisible = true
      this.relativeAlign = event.relativeAlign
      this.target = event.currentTarget
    },
    hide() {
      this.menuVisible = false
      this.target = false
      this.relativeAlign = false
    },
    onEnter() {
      this.appendContainer()
      this.alignContainer()
      this.bindOutsideClickListener()
      this.bindResizeListener()

      if (this.autoZIndex) {
        this.$refs.container.style.zIndex = String(this.baseZIndex + DomHandler.generateZIndex())
      }
    },
    onLeave() {
      this.unbindOutsideClickListener()
      this.unbindResizeListener()
    },
    alignContainer() {
      if (this.relativeAlign) {
        DomHandler.relativePosition(this.$refs.container, this.target)
      } else {
        DomHandler.absolutePosition(this.$refs.container, this.target)
      }
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.menuVisible && this.$refs.container && !this.isTargetClicked(event)) {
            if (this.$refs.container.contains(event.target)) {
              // one of the menu items has been clicked
              event.stopPropagation()
              if (event.cancelable) {
                event.preventDefault()
              }
              if (!this.$props.stayOpenAfterMenuItemClick) {
                this.hide()
              }
            } else {
              this.hide()
            }
          }
        }
        document.globalClick = this.outsideClickListener // TODO IMPROVE TO CATCH AppMenuItem click ?
        document.addEventListener('click', this.outsideClickListener)
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.globalClick = this.outsideClickListener = null
        document.removeEventListener('click', this.outsideClickListener)
        this.outsideClickListener = null
      }
    },
    bindResizeListener() {
      if (!this.resizeListener) {
        this.resizeListener = () => {
          if (this.menuVisible) {
            this.hide()
          }
        }
        window.addEventListener('resize', this.resizeListener)
      }
    },
    unbindResizeListener() {
      if (this.resizeListener) {
        window.removeEventListener('resize', this.resizeListener)
        this.resizeListener = null
      }
    },
    isTargetClicked() {
      return this.target && (this.target === event.target || this.target.contains(event.target))
    },
    appendContainer() {
      if (this.appendTo) {
        if (this.appendTo === 'body') {
          document.body.appendChild(this.$refs.container)
        } else {
          document.getElementById(this.appendTo).appendChild(this.$refs.container)
        }
      }
    },
    restoreAppend() {
      if (this.$refs.container && this.appendTo) {
        if (this.appendTo === 'body') {
          document.body.removeChild(this.$refs.container)
        } else {
          document.getElementById(this.appendTo).removeChild(this.$refs.container)
        }
      }
    },
    beforeDestroy() {
      this.restoreAppend()
      this.unbindResizeListener()
      this.unbindOutsideClickListener()
      this.target = null
    },
  },
}
</script>

<style scoped lang="postcss">
.menu {
  @apply shadow-ria-2 border-gray-light rounded-lg absolute bg-white;
}
</style>
